import {
	Input,
	Output,
	EventEmitter,
	Component,
	ViewChild,
	enableProdMode
} from "@angular/core";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
import deMessages from "devextreme/localization/messages/de.json";
import ptMessages from "devextreme/localization/messages/pt.json";
import { DxDataGridComponent } from "devextreme-angular";

if (!/localhost/.test(document.location.host)) {
	enableProdMode();
}

@Component({
	selector: "kt-grid",
	templateUrl: "./grid.component.html",
	styleUrls: ["./grid.component.scss"]
})
export class GridComponent {
	events: Array<string> = [];
	@ViewChild("targetDataGrid", { static: false })
	dataGrid: DxDataGridComponent;
	@Input() grid: Array<any> = [];
	@Input() dataSource: Array<any> = [];
	@Input() gridId: string = "gridContainer";
	@Input() id: string = "id";
	@Output() gridEmit: EventEmitter<any> = new EventEmitter();
	locale: string;

	constructor() {
		//this.locale = this.getLocale();
		loadMessages(deMessages);
		loadMessages(ptMessages);
		//        locale(navigator.language);
		locale("pt");
		/*
		setTimeout(() => {
			this.refresh();
			console.log("refresh");
		}, 5000);*/
	}

	isNotUndefined(val) {
		return typeof val === "object";
	}
	baseEmit(event) {
		console.log(event);
	}
	empty() {}
	logEvent(eventName, event) {
		//	this.events.unshift(eventName);
		//console.log(JSON.stringify(eventName));
		//console.log("key", event.key);
		//console.log("data", event.data);
		//console.log("datasss", event);
		this.gridEmit.emit({
			action: eventName,
			key: event.key,
			data: event.data
		});
	}

	refresh() {
		this.dataGrid.instance.refresh();
	}

	clearEvents() {
		this.events = [];
	}
}
