// Angular
import { Injectable } from "@angular/core";
// RxJS
import { Subject } from "rxjs";
import axios from "axios";
import { environment } from "../../../../../environments/environment";
@Injectable()
export class MenuConfigService {
	// Public properties
	onConfigUpdated$: Subject<any>;
	// Private properties
	private menuConfig: any = {};

	/**
	 * Service Constructor
	 */
	constructor() {
		// register on config changed event and set default config
		this.onConfigUpdated$ = new Subject();
	}

	/**
	 * Returns the menuConfig
	 */
	async getMenus() {
		let items: any = [];
		const result = await axios.get(
			`${environment.apiURL}/generatemenu/1`,
			{}
		);

		if (result.status === 200) {
			//console.log(JSON.stringify(result.data));
			const data = result.data;
			let section = "";

			await data.forEach(item => {
				let addItem: any = {};
				if (section !== item.section) {
					section = item.section;

					items.push({ section: item.section });
				}
				addItem.title = item.title;
				addItem.translate = item.translate;
				addItem.root = true;
				addItem.icon = item.icon;
				addItem.page = item.page;
				items.push(addItem);
			});
		}
		const defaults: any = {
			header: {
				self: {},
				items
			},
			aside: {
				self: {},
				items
			}
		};

		this.menuConfig = defaults;
		return this.menuConfig;
	}

	/**
	 * Load config
	 *
	 * @param config: any
	 */
	async loadConfigs(config: any) {
		//console.log(JSON.stringify(config));
		//this.menuConfig = config; //defaults;
		//this.onConfigUpdated$.next(this.menuConfig);

		let items: any = [];
		const result = await axios.get(
			`${environment.apiURL}/generatemenu/1`,
			{}
		);

		if (result.status === 200) {
			//console.log(JSON.stringify(result.data));
			const data = result.data;
			let section = "";

			await data.forEach(item => {
				let addItem: any = {};
				if (section !== item.section) {
					section = item.section;

					items.push({ section: item.section });
				}
				addItem.title = item.title;
				addItem.translate = item.translate;
				addItem.root = true;
				addItem.icon = item.icon;
				addItem.page = item.page;
				items.push(addItem);
			});
		}
		const defaults: any = {
			header: {
				self: {},
				items
			},
			aside: {
				self: {},
				items
			}
		};

		this.menuConfig = defaults;
		//console.log("config", this.menuConfig);
		this.onConfigUpdated$.next(this.menuConfig);
	}
}
