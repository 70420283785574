import { Component, Input } from "@angular/core";

@Component({
	selector: "kt-formcontent",
	templateUrl: "./form-content.component.html"
	//styleUrls: ["./input.component.scss"],
})
export class FormContentComponent {
	@Input() colMD: string = "12";
	@Input() label: string;

	@Input() separator: string = "false";

	constructor() {}
	hasSeparator() {
		return this.separator === "false" ? false : true;
	}
}
