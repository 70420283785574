import { Component, Input } from "@angular/core";

@Component({
	selector: "kt-radiochecklist",
	templateUrl: "./radio-check-list.component.html"
})
export class RadioCheckListComponent {
	@Input() cols: string;
	@Input() title: string = "";

	/*@Input() submitBtn: string = "";
	@Input() cancelBtn: string = "";
	@Input() submitClick: string = ""; */
	constructor() {}
	hasTitle() {
		return this.title.length > 0;
	}
}
