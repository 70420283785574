import {
	Component,
	ContentChild,
	Input,
	Output,
	OnInit,
	Renderer2,
	forwardRef,
	ElementRef,
	EventEmitter,
	AfterContentInit
} from "@angular/core";
import _ from "lodash";
import {
	FormControlName,
	SelectControlValueAccessor,
	NG_VALUE_ACCESSOR
} from "@angular/forms";
//import { TemplatePortalDirective } from "@angular/cdk/portal";

//import { Overlay, OverlayRef, OverlayConfig } from "@angular/cdk/overlay";
declare var $: any;
const noop = () => {};
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => SelectComponent),
	multi: true
};
@Component({
	selector: "kt-select",

	templateUrl: "./select.component.html",
	styleUrls: ["./select.component.scss"],

	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
/*OnInit, AfterContentInit, */
export class SelectComponent extends SelectControlValueAccessor
	implements OnInit, AfterContentInit {
	value: any;

	onChange: (_: any) => {};
	onTouched: () => void;
	//compareWith: (o1: any, o2: any) => boolean;
	//_compareWith: (o1: any, o2: any) => boolean;
	_compareFn(c1: any, c2: any): boolean {
		return c1 && c2 ? c1.key === c2.key : c1 === c2;
	}
	//implements OnInit, AfterContentInit, ControlValueAccessor {
	@Output() dropdownEventUpdate: EventEmitter<any> = new EventEmitter();
	//private selectedDropDownValue = "";
	@Input() dropDownListArray: Array<any>;
	@Input() selectedDropDownValue = "";
	//@Input("value") _value: string = "";
	@Input() selectName: string = "";
	@Input() selectId: string = "";

	@Input() isValidClass: string = "";
	@Input() defaultText: string = "";

	@Input() leftTxt1: string = "";
	@Input() leftTxt2: string = "";
	@Input() rightTxt: string = "";
	@Input() rightIcon: string = "";
	@Input() leftIcon: string = "";
	@Input() errorMessage: string = "";
	@Input() dateFormat: string = "";
	@Input() separator: string = "false";

	input: any;
	//using contentChild, the Angular can inject references to the component.
	//To make it, we need the interface AfterContentInit
	@ContentChild(FormControlName, { read: false, static: false })
	control: FormControlName; //added to use Reactive Forms
	//onChange: any = () => {};

	writeValue(value: any): void {
		console.log("value", value);
		if (value) {
			this.value.emit(value);
		}
	}
	registerOnChange(fn: (value: any) => any): void {
		console.log("change", fn);
		this.onChange = fn;

		this.propagateChange = fn;
		//this.onDropDownChange = fn;
	}
	registerOnTouched(fn: () => any): void {
		console.log("touched", fn);
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		console.log("disabled", isDisabled);
		throw new Error("Method not implemented.");
	}
	constructor(_renderer: Renderer2, _elementRef: ElementRef) {
		super(_renderer, _elementRef);
	}

	getChanged(event) {
		console.log("oia aqui", event.target.value);
	}
	propagateChange(time: any) {
		console.log("time", time);
	}

	ngOnInit() {
		$(document).ready(() => {
			$("#kt_select2_4").select2({
				placeholder: this.defaultText,
				allowClear: true
			});
			$("#kt_select2_4").on("change", e => {
				e.stopPropagation();
				console.log(e.target.value);
				console.log(this.defaultText);
				//		this.writeValue(e.target.value);

				this.propagateChange(e.target.value);

				this.onTouched();
				this.dropdownEventUpdate.emit({
					id: e.target.value
				});

				return false;
			});
		});
		//.bind(this);
	}

	ngAfterContentInit() {
		this.input = this.control;

		if (this.input === undefined) {
			throw new Error(
				"this componenet need to be used with a ngModel or FormControlName Directive"
			);
		}
	}

	hasSuccess(): boolean {
		//return false;
		//const ok = false;
		const ok = this.input.valid && (this.input.dirty || this.input.touched);

		if (ok) {
			this.isValidClass = "is-valid";
		}
		return false;
	}

	hasError(): boolean {
		const error =
			this.input.invalid && (this.input.dirty || this.input.touched);
		if (error) {
			this.isValidClass = "is-invalid";
		}
		return error;
	}

	hasLeftTxt1() {
		return this.leftTxt1.length > 0;
	}
	hasLeftTxt2() {
		return this.leftTxt2.length > 0;
	}
	hasRightTxt() {
		return this.rightTxt.length > 0;
	}
	hasLeftIcon() {
		return this.leftIcon.length > 0;
	}
	hasRightIcon() {
		return this.rightIcon.length > 0;
	}
}

/*
189222893
SEGURANCA SOCIAL 12081512493
15E QEN 6DZ */
