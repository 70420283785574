import {
	Component,
	OnInit,
	Input,
	ContentChild,
	AfterContentInit,
	forwardRef
} from "@angular/core";
import {
	FormControlName,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from "@angular/forms";

@Component({
	selector: "kt-button",
	host: {
		"(disabled)": "disabled($event.target)"
	},
	templateUrl: "./button.component.html",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ButtonComponent)
		}
	]
})
export class ButtonComponent
	implements OnInit, AfterContentInit, ControlValueAccessor {
	public _disabled: boolean = false;
	@Input() type: string;
	@Input() btnName: string;
	@Input() btnClass: string = "btn-primary";
	@Input()
	get disabled() {
		return this._disabled;
	}
	set disabled(dis) {
		this._disabled = dis;
	}

	@ContentChild(FormControlName, { read: false, static: true })
	control: FormControlName; //added to use Reactive Forms

	constructor() {}
	registerOnChange(fn) {
		//	this.onChange = fn;
	}
	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
	writeValue(value) {
		if (value) {
			//this.value = value;
		}
	}

	registerOnTouched(fn) {
		//this.onTouched = fn;
	}
	ngOnInit() {}
	ngAfterContentInit() {
		console.log("this control", this.control);
	}
}
