import { NgModule, ModuleWithProviders } from "@angular/core";
//basic directive
import { CommonModule } from "@angular/common";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { InputComponent } from "./input/input.component";
import { FormContainerComponent } from "./form-container/form-container.component";
import { FormFootComponent } from "./form-foot/form-foot.component";
/*
import { RadioComponent } from "./radio/radio.component";
import { RatingComponent } from "./rating/rating.component";

import { RestaurantService } from "../restaurant/restaurant.service";
import { ShoppingCartService } from "../restaurant-detail/shopping-cart/shopping-cart.service";
import { OrderService } from "../order/order.service";
import { SnackbarComponent } from "./messages/snackbar/snackbar.component";
import { NotificationService } from "./messages/notification.service";
import { LoginService } from "../security/login/login.service";
import { LoggedInGuard } from "../security/loggedin.guard";
import { LeaveOrderGuard } from "../order/leave-order.guard";
*/
//to work with interceptors need this dependence
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ButtonComponent } from "./button/button.component";
import { RadioCheckboxComponent } from "./radiocheckbox/radiocheckbox.component";
import { RadioCheckListComponent } from "./radio-check-list/radio-check-list.component";
import { SelectComponent } from "./select/select.component";
import { FormContentComponent } from "./form-content/form-content.component";
//import { NgSelect2Module } from "ng-select2";
//import { AuthInterceptor } from "../security/auth.interceptor";

import {
	DxDataGridModule,
	DxButtonModule,
	DxToastModule
} from "devextreme-angular";
import { GridComponent } from "./devextreme/grid/grid.component";

@NgModule({
	declarations: [
		InputComponent,
		FormContainerComponent,
		FormFootComponent,
		RadioCheckboxComponent,
		ButtonComponent,
		SelectComponent,
		FormContentComponent,
		RadioCheckListComponent,
		GridComponent
		/*RadioComponent,
		RatingComponent,
		SnackbarComponent */
	],
	imports: [
		DxButtonModule,
		DxDataGridModule,
		DxToastModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule
	],
	exports: [
		GridComponent,
		InputComponent,
		FormContainerComponent,
		ButtonComponent,
		SelectComponent,
		FormContentComponent,
		RadioCheckboxComponent,
		RadioCheckListComponent,
		FormFootComponent,
		ReactiveFormsModule,
		FormsModule /*,
		RadioComponent,
		RatingComponent,
		SnackbarComponent,
		CommonModule,

		 */
	]
})
export class SharedModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: SharedModule,
			providers: [
				/*OrderService
					ShoppingCartService,
				RestaurantService,
				NotificationService,
				LoginService,
				LoggedInGuard,
				LeaveOrderGuard, //criar classe, declarar como provider e associar na rota
				{
					provide: HTTP_INTERCEPTORS,
					useClass: AuthInterceptor,
					multi: true
				}*/
				//multi=true => to receive multi values
			]
		};
	}
}
