export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: "Dashboards",
					root: true,
					alignment: "left",
					page: "/dashboard",
					translate: "MENU.DASHBOARD"
				},
				{
					title: "MENU.COMPONENT",
					root: true,
					alignment: "left",
					toggle: "click",
					submenu: [
						{
							title: "Google Material",
							bullet: "dot",
							icon: "flaticon-interface-7",
							submenu: [
								{
									title: "Form Controls",
									bullet: "dot",
									submenu: [
										{
											title: "Auto Complete",
											page:
												"/material/form-controls/autocomplete",
											permission:
												"accessToECommerceModule"
										},
										{
											title: "Checkbox",
											page:
												"/material/form-controls/checkbox"
										},
										{
											title: "Radio Button",
											page:
												"/material/form-controls/radiobutton"
										},
										{
											title: "Datepicker",
											page:
												"/material/form-controls/datepicker"
										},
										{
											title: "Form Field",
											page:
												"/material/form-controls/formfield"
										},
										{
											title: "Input",
											page:
												"/material/form-controls/input"
										},
										{
											title: "Select",
											page:
												"/material/form-controls/select"
										},
										{
											title: "Slider",
											page:
												"/material/form-controls/slider"
										},
										{
											title: "Slider Toggle",
											page:
												"/material/form-controls/slidertoggle"
										}
									]
								},
								{
									title: "Navigation",
									bullet: "dot",
									submenu: [
										{
											title: "Menu",
											page: "/material/navigation/menu"
										},
										{
											title: "Sidenav",
											page: "/material/navigation/sidenav"
										},
										{
											title: "Toolbar",
											page: "/material/navigation/toolbar"
										}
									]
								},
								{
									title: "Layout",
									bullet: "dot",
									submenu: [
										{
											title: "Card",
											page: "/material/layout/card"
										},
										{
											title: "Divider",
											page: "/material/layout/divider"
										},
										{
											title: "Expansion panel",
											page:
												"/material/layout/expansion-panel"
										},
										{
											title: "Grid list",
											page: "/material/layout/grid-list"
										},
										{
											title: "List",
											page: "/material/layout/list"
										},
										{
											title: "Tabs",
											page: "/material/layout/tabs"
										},
										{
											title: "Stepper",
											page: "/material/layout/stepper"
										},
										{
											title: "Default Forms",
											page:
												"/material/layout/default-forms"
										},
										{
											title: "Tree",
											page: "/material/layout/tree"
										}
									]
								},
								{
									title: "Buttons & Indicators",
									bullet: "dot",
									submenu: [
										{
											title: "Button",
											page:
												"/material/buttons-and-indicators/button"
										},
										{
											title: "Button toggle",
											page:
												"/material/buttons-and-indicators/button-toggle"
										},
										{
											title: "Chips",
											page:
												"/material/buttons-and-indicators/chips"
										},
										{
											title: "Icon",
											page:
												"/material/buttons-and-indicators/icon"
										},
										{
											title: "Progress bar",
											page:
												"/material/buttons-and-indicators/progress-bar"
										},
										{
											title: "Progress spinner",
											page:
												"/material/buttons-and-indicators/progress-spinner"
										},
										{
											title: "Ripples",
											page:
												"/material/buttons-and-indicators/ripples"
										}
									]
								},
								{
									title: "Popups & Modals",
									bullet: "dot",
									submenu: [
										{
											title: "Bottom sheet",
											page:
												"/material/popups-and-modals/bottom-sheet"
										},
										{
											title: "Dialog",
											page:
												"/material/popups-and-modals/dialog"
										},
										{
											title: "Snackbar",
											page:
												"/material/popups-and-modals/snackbar"
										},
										{
											title: "Tooltip",
											page:
												"/material/popups-and-modals/tooltip"
										}
									]
								},
								{
									title: "Data table",
									bullet: "dot",
									submenu: [
										{
											title: "Paginator",
											page:
												"/material/data-table/paginator"
										},
										{
											title: "Sort header",
											page:
												"/material/data-table/sort-header"
										},
										{
											title: "Table",
											page: "/material/data-table/table"
										}
									]
								}
							]
						},
						{
							title: "Ng-Bootstrap",
							bullet: "dot",
							icon: "flaticon-web",
							submenu: [
								{
									title: "Accordion",
									page: "/ngbootstrap/accordion"
								},
								{
									title: "Alert",
									page: "/ngbootstrap/alert"
								},
								{
									title: "Buttons",
									page: "/ngbootstrap/buttons"
								},
								{
									title: "Carousel",
									page: "/ngbootstrap/carousel"
								},
								{
									title: "Collapse",
									page: "/ngbootstrap/collapse"
								},
								{
									title: "Datepicker",
									page: "/ngbootstrap/datepicker"
								},
								{
									title: "Dropdown",
									page: "/ngbootstrap/dropdown"
								},
								{
									title: "Modal",
									page: "/ngbootstrap/modal"
								},
								{
									title: "Pagination",
									page: "/ngbootstrap/pagination"
								},
								{
									title: "Popover",
									page: "/ngbootstrap/popover"
								},
								{
									title: "Progressbar",
									page: "/ngbootstrap/progressbar"
								},
								{
									title: "Rating",
									page: "/ngbootstrap/rating"
								},
								{
									title: "Tabs",
									page: "/ngbootstrap/tabs"
								},
								{
									title: "Timepicker",
									page: "/ngbootstrap/timepicker"
								},
								{
									title: "Tooltips",
									page: "/ngbootstrap/tooltip"
								},
								{
									title: "Typehead",
									page: "/ngbootstrap/typehead"
								}
							]
						}
					]
				},
				{
					title: "Applications",
					root: true,
					alignment: "left",
					toggle: "click",
					submenu: [
						{
							title: "eCommerce",
							bullet: "dot",
							icon: "flaticon-business",
							permission: "accessToECommerceModule",
							submenu: [
								{
									title: "Customers",
									page: "/ecommerce/customers"
								},
								{
									title: "Products",
									page: "/ecommerce/products"
								}
							]
						},
						{
							title: "User Management",
							bullet: "dot",
							icon: "flaticon-user",
							submenu: [
								{
									title: "Users",
									page: "/user-management/users"
								},
								{
									title: "Roles",
									page: "/user-management/roles"
								}
							]
						}
					]
				},
				{
					title: "Custom",
					root: true,
					alignment: "left",
					toggle: "click",
					submenu: [
						{
							title: "Error Pages",
							bullet: "dot",
							icon: "flaticon2-list-2",
							submenu: [
								{
									title: "Error 1",
									page: "/error/error-v1"
								},
								{
									title: "Error 2",
									page: "/error/error-v2"
								},
								{
									title: "Error 3",
									page: "/error/error-v3"
								},
								{
									title: "Error 4",
									page: "/error/error-v4"
								},
								{
									title: "Error 5",
									page: "/error/error-v5"
								},
								{
									title: "Error 6",
									page: "/error/error-v6"
								}
							]
						},
						{
							title: "Wizard",
							bullet: "dot",
							icon: "flaticon2-mail-1",
							submenu: [
								{
									title: "Wizard 1",
									page: "/wizard/wizard-1"
								},
								{
									title: "Wizard 2",
									page: "/wizard/wizard-2"
								},
								{
									title: "Wizard 3",
									page: "/wizard/wizard-3"
								},
								{
									title: "Wizard 4",
									page: "/wizard/wizard-4"
								}
							]
						}
					]
				}
			]
		},

		//LATERAL
		aside: {
			self: {},
			items: [
				{
					title: "Dashboard",
					root: true,
					icon: "flaticon2-architecture-and-city",
					page: "/dashboard",
					translate: "MENU.DASHBOARD",
					bullet: "dot"
				},
				{
					title: "Layout Builder",
					root: true,
					icon: "flaticon2-expand",
					page: "/builder"
				},

				{ section: "MENU.COMPANY" },

				{
					title: "MENU.LIST",
					translate: "MENU.LIST",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/company"
				},

				{
					title: "MENU.LIST",
					translate: "MENU.LIST",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/country"
				},

				{ section: "MENU.PEOPLE" },

				{
					title: "MENU.LIST",
					translate: "MENU.LIST",
					root: true,
					icon: "flaticon2-expand",
					page: "/people"
				},

				{
					title: "countries",
					translate: "countries",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/country"
				},
				{
					title: "companies",
					translate: "companies",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/company"
				},
				{
					title: "legal_natures",
					translate: "legal_natures",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/legalnature"
				},
				{
					title: "banks",
					translate: "banks",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/bank"
				},
				{
					title: "collective_agreements",
					translate: "collective_agreements",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/collaggreement"
				},
				{
					title: "assurance_policies",
					translate: "assurance_policies",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/assurancepolicy"
				},
				{
					title: "absenteeism",
					translate: "absenteeism",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/absenteeism"
				},
				{
					title: "contracts",
					translate: "contracts",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/contract"
				},
				{
					title: "people",
					translate: "people",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/people"
				},
				{
					title: "h_companies",
					translate: "h_companies",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hcompany"
				},
				{
					title: "cost_centers",
					translate: "cost_centers",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/costcenter"
				},
				{
					title: "categories",
					translate: "categories",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/category"
				},
				{
					title: "professional_status",
					translate: "professional_status",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/profstatus"
				},
				{
					title: "unions",
					translate: "unions",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/union"
				},
				{
					title: "h_addresses",
					translate: "h_addresses",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/haddress"
				},
				{
					title: "h_collective_agreements",
					translate: "h_collective_agreements",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hcollaggreement"
				},
				{
					title: "h_professional_status",
					translate: "h_professional_status",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hprofstatus"
				},
				{
					title: "h_workcenters",
					translate: "h_workcenters",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hworkcenter"
				},
				{
					title: "h_absenteeism",
					translate: "h_absenteeism",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/habsenteism"
				},
				{
					title: "professions",
					translate: "professions",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/profession"
				},
				{
					title: "h_categories",
					translate: "h_categories",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hcategory"
				},
				{
					title: "transfer_types",
					translate: "transfer_types",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/transtype"
				},
				{
					title: "h_unions",
					translate: "h_unions",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hunion"
				},
				{
					title: "h_irs_types",
					translate: "h_irs_types",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hirstype"
				},
				{
					title: "social_security_types",
					translate: "social_security_types",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/sstype"
				},
				{
					title: "h_education_levels",
					translate: "h_education_levels",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hedulevel"
				},
				{
					title: "h_banks",
					translate: "h_banks",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hbank"
				},
				{
					title: "irs_types",
					translate: "irs_types",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/irstype"
				},
				{
					title: "workunits",
					translate: "workunits",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/workunit"
				},
				{
					title: "h_social_security_types",
					translate: "h_social_security_types",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hsstype"
				},
				{
					title: "payment_types",
					translate: "payment_types",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/paytype"
				},
				{
					title: "h_working_time_types",
					translate: "h_working_time_types",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hwtimetype"
				},
				{
					title: "h_contracts",
					translate: "h_contracts",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hcontract"
				},
				{
					title: "h_assurance_policies",
					translate: "h_assurance_policies",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hassurancepolicy"
				},
				{
					title: "h_professions",
					translate: "h_professions",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hprefession"
				},
				{
					title: "workcenters",
					translate: "workcenters",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/workcenter"
				},
				{
					title: "roles",
					translate: "roles",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/role"
				},
				{
					title: "education_levels",
					translate: "education_levels",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/edulevel"
				},
				{
					title: "working_time_types",
					translate: "working_time_types",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/wtimetype"
				},
				{
					title: "h_workunits",
					translate: "h_workunits",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hworkunit"
				},
				{
					title: "h_cost_centers",
					translate: "h_cost_centers",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hcostcenter"
				},
				{
					title: "h_bank_data",
					translate: "h_bank_data",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hbankdata"
				},
				{
					title: "h_roles",
					translate: "h_roles",
					root: true,
					icon: "flaticon2-expand",
					page: "/base/hrole"
				},

				{ section: "Components" },
				{
					title: "Google Material",
					root: true,
					bullet: "dot",
					icon: "flaticon2-browser-2",
					submenu: [
						{
							title: "Form Controls",
							bullet: "dot",
							submenu: [
								{
									title: "Auto Complete",
									page:
										"/material/form-controls/autocomplete",
									permission: "accessToECommerceModule"
								},
								{
									title: "Checkbox",
									page: "/material/form-controls/checkbox"
								},
								{
									title: "Radio Button",
									page: "/material/form-controls/radiobutton"
								},
								{
									title: "Datepicker",
									page: "/material/form-controls/datepicker"
								},
								{
									title: "Form Field",
									page: "/material/form-controls/formfield"
								},
								{
									title: "Input",
									page: "/material/form-controls/input"
								},
								{
									title: "Select",
									page: "/material/form-controls/select"
								},
								{
									title: "Slider",
									page: "/material/form-controls/slider"
								},
								{
									title: "Slider Toggle",
									page: "/material/form-controls/slidertoggle"
								}
							]
						},
						{
							title: "Navigation",
							bullet: "dot",
							submenu: [
								{
									title: "Menu",
									page: "/material/navigation/menu"
								},
								{
									title: "Sidenav",
									page: "/material/navigation/sidenav"
								},
								{
									title: "Toolbar",
									page: "/material/navigation/toolbar"
								}
							]
						},
						{
							title: "Layout",
							bullet: "dot",
							submenu: [
								{
									title: "Card",
									page: "/material/layout/card"
								},
								{
									title: "Divider",
									page: "/material/layout/divider"
								},
								{
									title: "Expansion panel",
									page: "/material/layout/expansion-panel"
								},
								{
									title: "Grid list",
									page: "/material/layout/grid-list"
								},
								{
									title: "List",
									page: "/material/layout/list"
								},
								{
									title: "Tabs",
									page: "/material/layout/tabs"
								},
								{
									title: "Stepper",
									page: "/material/layout/stepper"
								},
								{
									title: "Default Forms",
									page: "/material/layout/default-forms"
								},
								{
									title: "Tree",
									page: "/material/layout/tree"
								}
							]
						},
						{
							title: "Buttons & Indicators",
							bullet: "dot",
							submenu: [
								{
									title: "Button",
									page:
										"/material/buttons-and-indicators/button"
								},
								{
									title: "Button toggle",
									page:
										"/material/buttons-and-indicators/button-toggle"
								},
								{
									title: "Chips",
									page:
										"/material/buttons-and-indicators/chips"
								},
								{
									title: "Icon",
									page:
										"/material/buttons-and-indicators/icon"
								},
								{
									title: "Progress bar",
									page:
										"/material/buttons-and-indicators/progress-bar"
								},
								{
									title: "Progress spinner",
									page:
										"/material/buttons-and-indicators/progress-spinner"
								},
								{
									title: "Ripples",
									page:
										"/material/buttons-and-indicators/ripples"
								}
							]
						},
						{
							title: "Popups & Modals",
							bullet: "dot",
							submenu: [
								{
									title: "Bottom sheet",
									page:
										"/material/popups-and-modals/bottom-sheet"
								},
								{
									title: "Dialog",
									page: "/material/popups-and-modals/dialog"
								},
								{
									title: "Snackbar",
									page: "/material/popups-and-modals/snackbar"
								},
								{
									title: "Tooltip",
									page: "/material/popups-and-modals/tooltip"
								}
							]
						},
						{
							title: "Data table",
							bullet: "dot",
							submenu: [
								{
									title: "Paginator",
									page: "/material/data-table/paginator"
								},
								{
									title: "Sort header",
									page: "/material/data-table/sort-header"
								},
								{
									title: "Table",
									page: "/material/data-table/table"
								}
							]
						}
					]
				},
				{
					title: "Ng-Bootstrap",
					root: true,
					bullet: "dot",
					icon: "flaticon2-digital-marketing",
					submenu: [
						{
							title: "Accordion",
							page: "/ngbootstrap/accordion"
						},
						{
							title: "Alert",
							page: "/ngbootstrap/alert"
						},
						{
							title: "Buttons",
							page: "/ngbootstrap/buttons"
						},
						{
							title: "Carousel",
							page: "/ngbootstrap/carousel"
						},
						{
							title: "Collapse",
							page: "/ngbootstrap/collapse"
						},
						{
							title: "Datepicker",
							page: "/ngbootstrap/datepicker"
						},
						{
							title: "Dropdown",
							page: "/ngbootstrap/dropdown"
						},
						{
							title: "Modal",
							page: "/ngbootstrap/modal"
						},
						{
							title: "Pagination",
							page: "/ngbootstrap/pagination"
						},
						{
							title: "Popover",
							page: "/ngbootstrap/popover"
						},
						{
							title: "Progressbar",
							page: "/ngbootstrap/progressbar"
						},
						{
							title: "Rating",
							page: "/ngbootstrap/rating"
						},
						{
							title: "Tabs",
							page: "/ngbootstrap/tabs"
						},
						{
							title: "Timepicker",
							page: "/ngbootstrap/timepicker"
						},
						{
							title: "Tooltips",
							page: "/ngbootstrap/tooltip"
						},
						{
							title: "Typehead",
							page: "/ngbootstrap/typehead"
						}
					]
				},
				{ section: "Applications" },
				{
					title: "eCommerce",
					bullet: "dot",
					icon: "flaticon2-list-2",
					root: true,
					permission: "accessToECommerceModule",
					submenu: [
						{
							title: "Customers",
							page: "/ecommerce/customers"
						},
						{
							title: "Products",
							page: "/ecommerce/products"
						}
					]
				},
				{
					title: "User Management",
					root: true,
					bullet: "dot",
					icon: "flaticon2-user-outline-symbol",
					submenu: [
						{
							title: "Users",
							page: "/user-management/users"
						},
						{
							title: "Roles",
							page: "/user-management/roles"
						}
					]
				},
				{ section: "Custom" },
				{
					title: "Error Pages",
					root: true,
					bullet: "dot",
					icon: "flaticon2-list-2",
					submenu: [
						{
							title: "Error 1",
							page: "/error/error-v1"
						},
						{
							title: "Error 2",
							page: "/error/error-v2"
						},
						{
							title: "Error 3",
							page: "/error/error-v3"
						},
						{
							title: "Error 4",
							page: "/error/error-v4"
						},
						{
							title: "Error 5",
							page: "/error/error-v5"
						},
						{
							title: "Error 6",
							page: "/error/error-v6"
						}
					]
				},
				{
					title: "Wizard",
					root: true,
					bullet: "dot",
					icon: "flaticon2-mail-1",
					submenu: [
						{
							title: "Wizard 1",
							page: "/wizard/wizard-1"
						},
						{
							title: "Wizard 2",
							page: "/wizard/wizard-2"
						},
						{
							title: "Wizard 3",
							page: "/wizard/wizard-3"
						},
						{
							title: "Wizard 4",
							page: "/wizard/wizard-4"
						}
					]
				}
			]
		}
	};

	public get configs(): any {
		return this.defaults;
	}
}
