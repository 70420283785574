import { Component, Input } from "@angular/core";

@Component({
	selector: "kt-form",
	templateUrl: "./form-container.component.html"
})
export class FormContainerComponent {
	@Input() cols: string;
	@Input() title: string;
	@Input() infoIcon: string = "flaticon-warning";
	@Input() infoText: string = "";
	/*@Input() submitBtn: string = "";
	@Input() cancelBtn: string = "";
	@Input() submitClick: string = ""; */
	constructor() {}
	hasInfo() {
		return this.infoIcon.length > 0 && this.infoText.length > 0;
	}
}
