import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
	try {
		enableProdMode();
	} catch (exception) {
		console.error(
			"BUGFIX: calling isDevMode() in imports before enableProdMode() throws exception - https://github.com//issues/8340#\n",
			exception
		);
	}
}
platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));
